import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Navbar from "../nav";
import axios from "axios";

export default function SignupForm() {
    const [formData, setFormData] = useState({
        mobile: '',
        email: '',
        username: '',
        otp: '',
        password: ''
    });

    const navigate = useNavigate();
    const [checking_user, setCheckingUser] = useState(false);
    const [sending_otp, setSendingOtp] = useState(false);
    const [logging_in, setLoggingIn] = useState(false);
    const [showOTPForm, setShowOTPForm] = useState(false);
    const [showPasswordForm, setShowPasswordForm] = useState(false);

    const [showRequestOtpButton, setShowRequestOtpButton] = useState(false);
    const [OTPRequested, setOTPRequested] = useState(false)

    // State to toggle password visibility
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmitPhone = async (event) => {
        event.preventDefault();
        try {
            setCheckingUser(true);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/checkuser`, { mobile: formData.mobile });
            const { exists } = response.data;
            if (exists) {
                setShowPasswordForm(true);
                setShowOTPForm(false);
            } else {
                setShowOTPForm(true);
                setShowPasswordForm(true);
                setSendingOtp(true);
                await axios.post(`${process.env.REACT_APP_API_URL}/sendotp`, { email: formData.email });
                alert('Welcome! We\'ve sent you a One Time Password to help verify your mobile number.');
            }
            setCheckingUser(false);
            setSendingOtp(false);
        } catch (error) {
            console.error("Error checking user:", error);
            setCheckingUser(false);
        }
    };

    const handleSubmitEmail = async (event) => {
        event.preventDefault();
        try {
            setCheckingUser(true);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/checkuser`, { email: formData.email });
            const { exists } = response.data;
            if (exists) {
                setShowPasswordForm(true);
                setShowOTPForm(false);
            } else {
                setShowRequestOtpButton(true)
            }
            setCheckingUser(false);
            setSendingOtp(false);
        } catch (error) {
            console.error("Error checking user:", error);
            setCheckingUser(false);
        }
    };

    const requestOTP = async () => {

        setSendingOtp(true);
        await axios.post(`${process.env.REACT_APP_API_URL}/sendotp`, { email: formData.email });
        alert('Welcome! We have sent you a verification code to help verify your email address. The code expires in 1hr');

        setShowOTPForm(true);
        setShowPasswordForm(true);

        setOTPRequested(true)
        setShowRequestOtpButton(false)
        setSendingOtp(false)
    }

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            setLoggingIn(true);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/signup`, formData);
            const { signed_in, access_token, registered, profile } = response.data;

            if (signed_in) {
                localStorage.setItem("verified_access_token", access_token);
                localStorage.setItem("verifiq_profile", JSON.stringify(profile));
                navigate("/topics");
            }

            if (registered) {
                alert("You have successfully registered. Please refresh the page to log in.");
                window.location.reload();
            }

            setLoggingIn(false);
        } catch (error) {
            setLoggingIn(false);
            if (error.response) {
                if (error.response.status === 400) {
                    alert(`Error: ${error.response.data.detail}`);
                } else if (error.response.status === 401) {
                    alert("Sorry, could not log you in, please check your credentials.");
                } else {
                    console.error("Error signing up:", error);
                }
            } else {
                console.error("Network or server error:", error);
            }
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    return (
        <>
            <Navbar />
            <div className="p-4">
                <div className="card bg-white p-6 rounded-lg shadow-md">
                    <h2 className="font-semibold text-2xl mb-2 mt-2">Welcome! </h2>
                    <span className="text-gray-600 text-sm">Learning shouldn't be so boring, enter your phone number to get access</span>
                    <br />

                    <hr className="mt-6" />
                    <form className="mt-4" onSubmit={showPasswordForm ? handleLogin : handleSubmitEmail}>
                        <div className="mb-6">
                            <label className="text-sm block text-gray-700 font-bold mb-2" htmlFor="email">
                                Email address
                            </label>
                            <input autoComplete="off" type="email" name="email" placeholder="Type here" className="input input-sm input-bordered w-full" onChange={handleChange} />
                        </div>

                        {(showRequestOtpButton && !OTPRequested) && (<>
                            <p className="text-sm text-gray-600">Hello! We have noticed you don't have an account yet, click the button below so we can send you a verification code to your email address</p>
                            <button disabled={sending_otp} className="btn btn-primary" onClick={requestOTP}>
                                {sending_otp ? 'Please wait...' : showOTPForm ? 'Finish' : 'Request Code'}
                            </button>

                            <button disabled={sending_otp} className="ml-4 btn btn-default" onClick={() => {
                                setShowOTPForm(true);
                                setShowPasswordForm(true);

                                setOTPRequested(true)
                                setShowRequestOtpButton(false)
                                setSendingOtp(false)
                            }}>
                                I have a code
                            </button>
                            <br></br>
                            <br></br>
                        </>)}

                        {showOTPForm && (
                            <>
                                <div className="mb-6" style={{
                                    border: "2px solid #aba1a13d",
                                    borderRadius: "12px",
                                    padding: "10px"
                                }}>
                                    <label className="text-sm block text-gray-700 font-bold mb-2" htmlFor="otp">
                                        Verfication code <span className="mb-1 text-sm btn btn-default btn-sm" style={{ cursor: 'pointer', fontSize: '11px', float: 'right' }} onClick={requestOTP}>Request Code</span>
                                    </label>
                                    <input type="text" name="otp" placeholder="Enter OTP" className="input input-sm input-bordered w-full" onChange={handleChange} />
                                </div>

                                <div className="mb-6">
                                    <label className="text-sm block text-gray-700 font-bold mb-2" htmlFor="mobile">
                                        Mobile number (for payments)
                                    </label>
                                    <input autoComplete="off" type="text" name="mobile" placeholder="Type here" className="input input-sm input-bordered w-full" onChange={handleChange} />
                                </div>

                                <div className="mb-6">
                                    <label className="text-sm block text-gray-700 font-bold mb-2" htmlFor="username">
                                        Username (for profile)
                                    </label>
                                    <input type="text" name="username" placeholder="Enter Username" className="input input-sm input-bordered w-full" onChange={handleChange} />
                                </div>
                            </>
                        )}

                        {showPasswordForm && (
                            <div className="mb-6">
                                <label className="text-sm block text-gray-700 font-bold mb-2" htmlFor="password">
                                    Password
                                </label>
                                <div className="relative">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        name="password"
                                        placeholder="Enter Password"
                                        className="input input-sm input-bordered w-full"
                                        onChange={handleChange}
                                    />
                                    <span
                                        className="absolute right-2 top-2 cursor-pointer"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword ? '👁️' : '👁️‍🗨️'}
                                    </span>
                                </div>
                            </div>
                        )}

                        {!showRequestOtpButton && (<div className="mb-6">
                            <button disabled={logging_in || checking_user || sending_otp} className="btn btn-primary" type="submit">
                                {logging_in || checking_user || sending_otp ? 'Please wait...' : showOTPForm ? 'Finish' : 'Continue'}
                            </button>
                            <Link className="ml-4 btn btn-warning" to="/" title="See yo next time &#x1F60E;">
                                Not right now
                            </Link>
                        </div>
                        )}
                    </form>

                    <hr className="mt-10" />
                    <br></br>
                    <p className="mt-2 text-sm text-gray-500 mb-10">
                        Please read our <Link to="/terms" className="text-blue-600">Terms & Conditions</Link> before you proceed to do anything. Continuing will mean you agree to our Terms & Conditions.
                    </p>
                </div>
            </div>
        </>
    );
}
