import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../nav';

export default function WithdrawCredits() {
    const [formData, setFormData] = useState({
        userPhoneNumber: '',
        userName: '',
        creditsToWithdraw: Math.round(10 * 2.456),
        totalAmount: 0,
        withdrawFromSystem: true,
    });
    const [access_token] = useState(localStorage.getItem('verified_access_token'));
    const [loading, setLoading] = useState(false);
    const [profile, setProfile] = useState(null);

    // Exchange rate
    const exchange_rate = process.env.REACT_APP_EXCHANGE_RATE;
    const system_commission = process.env.REACT_APP_CREDIT_SALE_SYSTEM_COMMISION;

    useEffect(() => {

        loadProfileFromLocalStorage()


        // Calculate total amount whenever creditsToWithdraw or withdrawFromSystem changes
        const credits = parseInt(formData.creditsToWithdraw, 10);
        if (!isNaN(credits)) {
            const totalAmount = formData.withdrawFromSystem
                ? (credits / exchange_rate)// * (1 - parseFloat(system_commission)) // Calculate total amount with [- 15%] transaction fee
                : credits / exchange_rate; // Flat rate without transaction fee
            setFormData(prevState => ({
                ...prevState,
                totalAmount
            }));

            // console.log("AMOUNT OF CREDITS", totalAmount);

        } else {
            setFormData(prevState => ({
                ...prevState,
                totalAmount: 0
            }));
        }
    }, [formData.creditsToWithdraw, formData.withdrawFromSystem]);

    const loadProfileFromLocalStorage = () => {
        const storedProfile = localStorage.getItem('verifiq_profile');
        if (storedProfile !== null) {
            const parsedProfile = JSON.parse(storedProfile);
            setProfile(parsedProfile);
        } else {
            setProfile(null);
        }
    };

    const handleWithdrawCredits = async (e) => {
        e.preventDefault();



        try {
            setLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/sell-credits`, {
                mobile: formData.userPhoneNumber,
                credits: formData.creditsToWithdraw,
                totalAmount: formData.totalAmount,
                buyFromSystem: formData.withdrawFromSystem,
            }, {
                headers: {
                    'Authorization': `Token ${access_token}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response?.data?.success) {
                let tracking_id = response?.data?.tracking_id;
                alert('Credits transfered successfully! If you shared to your friends, that\'s just amazing, if you sent it to us, we shall reward you. Here is your tracking ID: ' + tracking_id);
                // Update user credits here if needed

                const response_ = await axios.get(`${process.env.REACT_APP_API_URL}/me`, {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                });

                const { user } = response_.data;
                localStorage.setItem("verifiq_profile", JSON.stringify(user));
                const parsedProfile = user;
                setProfile(parsedProfile);
                window.location.reload()
            } else {
                alert(response?.data?.message ?? "Error withdrawing credits. Please try again later.");
            }
        } catch (error) {
            console.error('Error withdrawing credits:', error.data);
            alert('Error withdrawing credits. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    const handleSelectContact = async () => {
        if (!('contacts' in navigator && 'ContactsManager' in window)) {
            alert('Contacts API not supported on this device');
            return;
        }

        try {
            const contacts = await navigator.contacts.select(['name', 'tel'], { multiple: false });
            if (contacts.length === 0) return;

            const contact = contacts[0];
            setFormData(prevState => ({
                ...prevState,
                userPhoneNumber: contact.tel[0],
                userName: contact.name[0],
            }));
        } catch (error) {
            console.error('Error selecting contact:', error);
        }
    };

    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleWithdrawToSystemChange = () => {
        setFormData(prevState => ({
            ...prevState,
            withdrawFromSystem: !prevState.withdrawFromSystem,
        }));
    };

    if (!profile) return <div>Loading...</div>


    return (
        <>
            <Navbar />

            <form onSubmit={handleWithdrawCredits} method='post' className='m-4'>
                <div className='card bg-green-100 p-4 mt-6 shadow-2xl bg-light-ggreen'>
                    <div className='border-b-2 border-gray-400'>
                        <h3 className='font-bold text-lg text-gray-700'>Share/Withdraw Credits</h3>
                        <p className='text-sm text-gray-500 mb-2'>You can swap your credits for cash.</p>
                        <span className='badge badge-success text-xs mb-4'>KES 1 = {exchange_rate} credits</span>
                    </div>

                    <div className='mt-4 mb-6' style={{
                        background: 'rgba(142, 150, 140, 0.19)',
                        borderRadius: '10px',
                        padding: '10px'
                    }}>
                        <label className='text-gray-700 text-sm font-semibold'>How do you wish to withdraw:</label>
                        <div>
                            <div className="form-control">
                                <label className="label cursor-pointer">
                                    <span className="label-text">Send me cash(15% fee)</span>
                                    <input type="radio" className="radio radio-info checked:bg-green-600" checked={formData.withdrawFromSystem} onChange={handleWithdrawToSystemChange} />
                                </label>
                            </div>

                            <div className="form-control">
                                <label className="label cursor-pointer">
                                    <span className="label-text">Transfer credits</span>
                                    <input type="radio" className="radio radio-info checked:bg-green-600" checked={!formData.withdrawFromSystem} onChange={handleWithdrawToSystemChange} />
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="form-control mb-6 mt-4" style={{
                        background: 'rgba(142, 150, 140, 0.11)',
                        borderRadius: '10px',
                        padding: '10px'
                    }}>
                        <label className='text-gray-700 text-sm font-semibold mb-2'>{formData.withdrawFromSystem ? "Your " : "Receiver's "} phone number</label>
                        <div className="flex space-x-2">
                            <input type="tel"
                                className={`input input-sm ${formData.withdrawFromSystem ? 'w-full' : 'min-w-40'}`}
                                name="userPhoneNumber"
                                value={formData.userPhoneNumber}
                                onChange={handleInputChange}
                                placeholder="Enter phone no." />

                            {!formData.withdrawFromSystem && <button type="button" className="btn btn-secondary btn-sm" onClick={handleSelectContact}>
                                Contacts
                            </button>}
                        </div>
                        <span className="text-xs text-gray-600">{formData.withdrawFromSystem ? 'Enter your phone number for receiving cash' : 'Enter the receiver\'s phone number or select from your contact list.'}</span>
                        <p className="text-xs text-gray-600 mb-2">{formData.withdrawFromSystem ? '' : <span className='font-semibold'>We assume you and your friend have come to an agreement on credit worth and have agreed to swap the credits.</span>}</p>

                    </div>

                    <div className='form-control' style={{
                        background: 'rgba(142, 150, 140, 0.11)',
                        borderRadius: '10px',
                        padding: '10px'
                    }}>
                        <label className='text-gray-700 text-sm font-semibold mb-2'>How many credits do you want to {formData.withdrawFromSystem ? 'redeem' : 'send'}? </label>
                        <input type="number" required
                            className='input w-full'
                            name="creditsToWithdraw" value={formData.creditsToWithdraw}
                            onChange={handleInputChange}
                            max={profile?.credit_balance ?? 10000}
                            min={Math.round(10 * 2.456)}
                            placeholder="Enter credits" />
                    </div>

                    <div className='mt-2 text-gray-600 text-sm'>
                        <p>Total amount to receive: KES {formData.totalAmount.toFixed(2)}</p>
                    </div>

                    <div className='mt-4'>
                        <button type='submit' className='btn btn-primary' disabled={loading}>{loading ? 'Loading...' : formData.withdrawFromSystem ? 'Share now' : 'Send Credits'}</button>
                    </div>
                </div>

                <hr className='mt-6 text-green-900' />

                <p className='mt-6 text-xs text-gray-600'>By clicking <b>{formData.withdrawFromSystem ? 'Withdraw Cash' : 'Send Credits'}</b>, you agree to our <a href='/#/terms' className='text-primary'>Terms of Service</a></p>
            </form>
        </>
    );
}
