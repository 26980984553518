import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../nav';
import { motion } from 'framer-motion';
import axios from 'axios';

export default function Home() {
    const topics = ['math', 'physics', 'driving', 'cooking', 'coding', 'history', 'music', 'art', 'sports', 'biology', 'chemistry', 'astronomy', 'literature', 'finance', 'photography', 'gardening', 'engineering', 'medicine', 'geography', 'languages']; // Expanded list of topics
    const [currentTopicIndex, setCurrentTopicIndex] = useState(0);
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [access_token, setAccessToken] = useState(localStorage.getItem('verified_access_token'))
    const [user, setUser] = useState(null)

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentTopicIndex((prevIndex) => (prevIndex + 1) % topics.length); // Cycle through topics
        }, 2000); // Change topic every 2 seconds

        getUser()

        return () => clearInterval(interval); // Cleanup interval
    }, [topics.length]);

    const getUser = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/me`, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });
            const { signed_in, user } = response.data;
            if (signed_in) {
                setIsLoggedIn(true)
                setUser(user)
            }
        } catch (error) {
            console.log("checking user failed");

        }
    }

    return (
        // <div className="flex flex-col items-center justify-center min-h-screen text-gray-50" style={{
        //     backgroundColor: 'rgb(4, 30, 15)'
        // }}>
        //     <h1 className="text-4xl font-bold mb-8">verifiQ.co.ke</h1>
        //     <p className="mb-4">Choose your language</p>
        //     <button className="btn btn-outline mb-2 text-gray-100">
        //         <span role="img" aria-label="UK Flag">🇬🇧</span> English
        //     </button>
        //     <button className="btn btn-outline mb-2 text-gray-50">
        //         <span role="img" aria-label="Swedish Flag">🇸🇪</span> Swahili
        //     </button>
        // </div>

        <div className='min-h-screen bg-base-200'>
            <Navbar />

            <div className="hero" style={{ minHeight: '90vh' }}>
                <div className="hero-content text-center">
                    <div className="max-w-md">
                        <img src={'/images/logo.png'} alt='verifiq'></img>
                        <br></br>
                        {/* <br></br>
                        <h1 className="text-5xl font-bold">Welcome!</h1> */}
                        <motion.p
                            className="py-6"
                            initial={{ opacity: 0 }} // Initial opacity
                            animate={{ opacity: 1 }} // Animate to full opacity
                            transition={{ duration: 0.5 }} // Animation duration
                        >
                            How good are you at <span className="text-2xl font-bold text-gray-800">{topics[currentTopicIndex]}</span>?
                            <br></br>
                            <span className='text-sm text-gray-700'>Join a live contest and find out!</span>
                        </motion.p>

                        <Link className="btn btn-warning btn-sm" title='Join an active contest' to="/topics">Contests &#x1F60B;</Link>

                        {(user && ['mxmandela@gmail.com', 'omondi.fidel42@gmail.com'].includes(user.email)) && (
                            <Link to="/contests/create" className="btn btn-primary btn-sm ml-2" title='Create your own contest for others to join, then you can add as many contests'>Create a contest </Link>
                        )}

                        <br></br>

                        <div className='mt-10'>
                            {!isLoggedIn && (
                                <><Link to={'/join'} className='link'>Sign up</Link>
                                    &nbsp;
                                    &nbsp;
                                </>
                            )}
                            <Link to={'/terms'} className='link'>Terms & Conditions</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}
