import axios from 'axios';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

export default function Navbar({ credit_balance = 0 }) {

    const exchange_rate = process.env.REACT_APP_EXCHANGE_RATE

    const [profile, setProfile] = useState(null);
    const [claiming_credits, setClaimingCredits] = useState(false)
    const [access_token, setAccessToken] = useState(localStorage.getItem('verified_access_token'))

    const handleLogout = () => {
        localStorage.removeItem('verified_access_token');
        localStorage.removeItem('verifiq_profile');
        window.location.href = '/#/join';
    };

    useEffect(() => {
        const storedProfile = localStorage.getItem('verifiq_profile');
        if (storedProfile !== null) {
            const parsedProfile = JSON.parse(storedProfile);
            setProfile(parsedProfile);
        } else {
            setProfile(null);
        }
    }, []);

    async function claimCredits() {
        setClaimingCredits(true)
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/claim-free-credits`, {
            headers: {
                Authorization: `Bearer ${access_token}`
            }
        });
        const data = response.data;
        if (data) {
            alert(data.message);

            const response = await axios.get(`${process.env.REACT_APP_API_URL}/me`, {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            });

            const { user } = response.data;
            localStorage.setItem("verifiq_profile", JSON.stringify(user));
            const parsedProfile = user;
            setProfile(parsedProfile);
        }

        setClaimingCredits(false)
    }

    return (
        <div className=''>
            {
                claiming_credits && (
                    <>
                        <div className='alert alert-primary'>
                            <p>Getting free credits</p>
                        </div>
                        <br></br>
                    </>
                )
            }

            <nav className="navbar bg-gray-700 text-gray-100 border-b">
                <div className='p-25 container mx-auto'>
                    <Link to="/" className="flex-1 text-lg font-bold">
                        {/* <span style={{
                            padding: '10px',
                            border: '1px solid #838c9a',
                            borderRadius: '10px'
                        }}>verifiQ &#x2714;</span> */}
                        <img src={'/images/logo-white.png'} alt="verifiq" width={90} />
                    </Link>

                    <div className="flex-none">
                        <div className="dropdown dropdown-end mr-4">
                            <div tabIndex={0} role="button" className="btn btn-ghost btn-circle px-15">
                                <div className="indicator">
                                    <span>&#x1F4B0;</span>
                                    <span className="badge badge-sm indicator-item">{Math.round(profile?.credit_balance ?? 0)}</span>
                                </div>
                            </div>

                            <div tabIndex={0} className="mt-1 z-[1] card card-compact dropdown-content w-52 bg-green-100 shadow">
                                <div className="card-body">
                                    {profile && (<span className="font-bold text-lg text-primary">{Math.round(profile?.credit_balance ?? 0)} Credits</span>)}
                                    {profile && (<span className="text-secondary text-sm">~ KES {profile?.credit_balance ?? 0 ? Math.round(parseInt(profile?.credit_balance ?? 0) / exchange_rate) : 0}</span>)}
                                    <div className="card-actions mt-2">
                                        {profile && (<button className="btn-block btn btn-default btn-sm" disabled={claiming_credits} onClick={claimCredits}>
                                            {claiming_credits ? 'Claiming...' : 'Claim free credits'}
                                        </button>)}

                                        {/* {profile && (<Link to={'/market'} className="btn btn-default btn-sm btn-block ">Buy credits</Link>)} */}
                                        {(profile?.credit_balance > 0) && <Link to={'/market/withdraw'} className="btn btn-default btn-sm btn-block btn-blockmb-1">Share credeits</Link>}
                                        <button className="btn btn-primary btn-sm btn-block" onClick={handleLogout}>
                                            {profile ? 'Logout' : 'Login'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    );
}
