import React, { useState, useEffect } from 'react';
import Navbar from '../../nav';
// import questions from '../questions';
import Question from '../question-component';
import ProgressBar from '../helpers/progerssbar'; // Import the ProgressBar component
import { Link } from 'react-router-dom';
import ContestCountdown from '../helpers/countdown';
import axios from 'axios';
import Loader from '../../loader';
// import { useWebSocket } from '../ws-context';

import moment from 'moment';
import ContestResults from './results';

const GameComponent = ({ contest_details, access_token }) => {
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [selectedAnswers, setSelectedAnswers] = useState([]);
    const [timeRemaining, setTimeRemaining] = useState(0);
    const [submissionMessage, setSubmissionMessage] = useState('');
    const [isTimeUp, setIsTimeUp] = useState(false);
    const [progress, setProgress] = useState(0);

    const [loading, setLoading] = useState(true)

    const [currentQuestionResponse, setCurrentQuestionResponse] = useState(false)
    const [loadingResults, setLoadingResults] = useState(true)
    const [disableResponding, setDisableResponding] = useState(false)

    const [userScore, setUserScore] = useState(null)

    const [questions, setQuestions] = useState([])

    const [show_question, setShowQuestion] = useState(true)


    // console.log("CURRENT TIME: ", moment().utc(true))
    // console.log("END TIME: ", moment.utc(contest_details.end_time));

    // console.log("DIFF", moment.utc(contest_details.end_time).diff(moment().utc(true), 'seconds'));

    useEffect(() => {

        // Get end time of the contest
        const endTime = new Date(contest_details.end_time).getTime();

        // Function to update remaining time
        const updateRemainingTime = () => {
            const currentTime = new Date().getTime();

            // const remainingTime = Math.round(Math.max(0, endTime - currentTime) / 1000); // Convert milliseconds to seconds

            const remainingTime = moment.utc(contest_details.end_time).diff(moment().utc(true), 'seconds')

            setTimeRemaining(remainingTime);
            if (remainingTime === 0 || remainingTime < 0) {
                setIsTimeUp(true);
                // clearInterval(timer);
            }
        };

        // Initial update
        updateRemainingTime();

        // Start updating time every second
        const timer = setInterval(updateRemainingTime, 1000);

        return () => clearInterval(timer);
    }, [contest_details]);


    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/contests/` + contest_details.id + '/questions', {
                    headers: {
                        Authorization: `Bearer ${access_token}`
                    }
                });
                const data = response.data;
                setQuestions(data);
                setLoading(false); // Move setLoading inside the try block to handle loading state properly
            } catch (error) {
                console.error("Error fetching questions:", error);
            }
        };

        // Fetch questions only if questions state is empty
        if (questions.length === 0) {
            fetchQuestions();
        }
    }, []);


    useEffect(() => {
        const calculateProgress = () => {
            const answeredQuestions = selectedAnswers.length; // Use the length of selectedAnswers array
            const totalQuestions = questions.length;
            const calculatedProgress = (answeredQuestions / totalQuestions) * 100;
            setProgress(calculatedProgress);
        };

        calculateProgress();
    }, [selectedAnswers]);

    useEffect(() => {
        setLoading(false);
    }, [loading])

    const handleSelectOption = async (selectedOption) => {
        setShowQuestion(false)

        setDisableResponding(currentQuestionIndex == selectedOption.question_id);

        const updatedSelectedAnswers = [...selectedAnswers];
        updatedSelectedAnswers[currentQuestionIndex] = { question_id: selectedOption.question_id, answer_id: selectedOption.id }; // Store question-answer pair
        setSelectedAnswers(updatedSelectedAnswers);

        // Move to next question automatically
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/contests/${contest_details.id}/questions/${selectedOption.question_id}/answers`, {
            question_id: selectedOption.question_id,
            answer_id: selectedOption.id
        }, {
            headers: {
                Authorization: `Bearer ${access_token}`
            }
        });

        const data = response.data;
        setCurrentQuestionResponse(data.data.is_correct);

        setTimeout(() => {
            setCurrentQuestionResponse(false);
            setShowQuestion(true)
            handleNext();
        }, 200);
    };

    const handleNext = () => {
        if (currentQuestionIndex == questions.length - 1) {
            setShowQuestion(false)
        }

        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
            setDisableResponding(false);
        }
    };

    const handlePrevious = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    const handleSubmit = async () => {
        setLoadingResults(true)
        // Assuming this is where you'd perform submission logic
        setSubmissionMessage('Answers submitted successfully, results are on the way...');
        setIsTimeUp(false); // Stop showing the time's up message

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/contests/${contest_details.id}/complete`,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            }
        )

        setSubmissionMessage('Answers submitted successfully, here are your results!');
        setLoadingResults(false)
        setUserScore(response.data.score_percentage);
    };

    const formatTime = time => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    const showLeaderBoard = async () => {
        console.log("Show leaderboard");

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/contests/${contest_details.id}/rank`,
            {
                headers: {
                    Authorization: `Bearer ${access_token}`
                }
            }
        )

        // console.log("Res", response.data);
    }

    if (!questions.length) return (
        <Loader />
    )


    const currentQuestion = questions[currentQuestionIndex];

    if (loading) return (
        <>One second...</>
    )

    if (contest_details.status) return (
        <>
            <div role="alert" className="text-left mb-4 alert alert-default bg-red-200 border-none">
                <div>
                    <h3 className="font-bold">Whops! This contest has ended</h3>
                    <div className="text-xs">See you in the next contest!</div>
                </div>
                <Link className="btn btn-sm btn-error" to={'/topics'}>See  more contests</Link>
            </div>
        </>
    )

    const joinC = async () => {
        // console.log("at", access_token);

        const response = await axios.get(`${process.env.REACT_APP_API_URL}/contests/` + contest_details.id + '/save-rank', {
            headers: {
                'Authorization': `Bearer ${access_token}`
            }
        });

        // console.log(response.data);
    }


    return (
        <>
            {
                (!submissionMessage && isTimeUp) && (
                    <div role="alert" className="text-left mb-4 alert alert-default bg-red-200">
                        <div>
                            <h3 className="font-bold">Whops! Time's up!</h3>
                            <div className="text-xs">See you in the next round!</div>
                        </div>
                        <Link className="btn btn-sm btn-error" to={'/topics'}>See  more contests</Link>

                        {/* <br></br>
                        <button className='btn btn-primary btn-sm mr-2' onClick={joinC}>join </button> */}

                        <ContestResults contestId={contest_details.id} access_token={access_token} />
                    </div>
                )
            }

            {(submissionMessage) && (
                <div>
                    <div role="alert" className="text-left mb-4 alert alert-success bg-green-200 border-none shadow-lg text-primary">
                        <div>
                            <h3 className="font-bold">Awesome!</h3>
                            <div className="text-xs">{submissionMessage}</div>
                        </div>
                    </div>

                    {!loadingResults && (<>
                        <br></br>
                        <ProgressBar progress={userScore} />
                        <br></br>
                        <br></br>

                        <Link className="text-left btn btn-sm btn-success" to="/topics">See  more contests</Link>
                    </>)}

                    <br></br>
                    <br></br>
                    <button className='btn btn-primary' onClick={showLeaderBoard}>Leaderboard</button>

                </div>
            )}

            {(!isTimeUp && !submissionMessage) && (
                <>
                    <h1 className="text-center text-2xl font-bold mb-3">Good luck! &#x1F91E;</h1>

                    <div className={`flex stats stats-vertical lg:stats-horizontal shadow-md bg-${currentQuestionResponse == true ? 'green' : 'yellow'}-200 mb-6`}>

                        <div className="stat">
                            <div className="stat-value text-gray-600">&#x23F1; {formatTime(timeRemaining)}</div>
                            <div className="stat-desc"><i>Time waits for no man!</i></div>
                        </div>

                        <div className="stat border-none">
                            <ProgressBar progress={progress} />
                        </div>
                    </div>

                    <div>
                        <div role="alert" className="text-left alert shadow-lg mb-2">
                            <div>
                                <h3 className="font-bold">{contest_details.title}</h3>
                                <div className="text-xs">{contest_details.description}</div>
                            </div>
                        </div>
                    </div>

                    <div className='mt-2'>

                        {show_question && (
                            <div className='card bg-gray-50 p-5 mb-4 text-sm'>
                                <Question
                                    question={currentQuestion}
                                    options={currentQuestion.choices}
                                    onSelect={handleSelectOption}
                                    selectedOption={selectedAnswers[currentQuestionIndex]?.answer_id} // Use selected answer from selectedAnswers
                                    currentQuestionResponse={currentQuestionResponse}
                                    disableResponding={currentQuestionResponse}
                                />
                            </div>
                        )}

                        {!show_question && (
                            <p className={`alert text-left text-sm alert-${currentQuestionResponse == true ? 'success' : 'warning'}`}>{(currentQuestionIndex == questions.length - 1) ? "Well done! Click Complete below to finish before time runs out!" : "Loading next question"}</p>
                        )}

                        <br />

                        <div className="flex justify-between">
                            {/* {currentQuestionIndex > 0 && (
                                <button className="btn btn-secondary" onClick={handlePrevious}>
                                    &#x1F448; Back
                                </button>
                            )} */}
                            {/* {currentQuestionIndex < questions.length - 1 && (
                                <button className="btn btn-primary" onClick={handleNext}>
                                    Next 	&#x1F449;
                                </button>
                            )} */}
                            {currentQuestionIndex === questions.length - 1 && (
                                <button className="btn btn-warning" onClick={handleSubmit}>
                                    Complete
                                </button>
                            )}
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

export default GameComponent;
